<template>
  <div style="background: #F4F7FC">
    <div class="header">
      <div class="header-main">
        <div class="header-img">
<!--          <img :src="dataCont.photo || '../../../assets/img/default-header.png'" alt="" class="header-img-head-picture">-->
          <el-image
              :fit="'contain'"
              :preview-src-list="[dataCont.photo]"
              :src="dataCont.photo"
              style="width: 100%; height: 100%;border-radius: 50%">
            <div slot="error" class="image-slot">
              <img alt="" src="../../../../assets/img/default-header.png" >
            </div>
          </el-image>
          <img v-if="dataCont.student_gender === '男'" :src="$OssAddress+'boy.png'" alt="" class="header-img-gender">
          <img v-else :src="$OssAddress+'girl.png'" alt="" class="header-img-gender">
        </div>
        <div class="header-info">
          <div style="font-size: 21rem">{{ dataCont.student_name }}</div>
          <div style="display: flex">
            <infoItem label="学号" label-width="96" width="300" :value="dataCont.student_no" />
            <infoItem label="年龄" label-width="96" width="300" :value="dataCont.age" />
          </div>
          <div style="display: flex">
            <infoItem label="校区" label-width="96" width="300" :value="dataCont.school_name" />
            <infoItem label="行政班" label-width="96" width="300" :value="dataCont.class_name" />
          </div>
          <infoItem label="完成时间" label-width="96" width="300" :value="dataCont.updated_at" />
        </div>
      </div>
    </div>

    <div class="main">
      <div class="main-title">登记信息</div>
      <div class="main-body">
        <div style="margin-bottom: 30rem" v-for="(item, index) in dataCont.question_answers">

          <div v-if="item.type === 3">
            <p>{{index + 1}}. {{ item.name }} <span style="color: #666">「 填空题 」</span></p>
            <div class="flex" style="margin-top: 10rem">
              <img :src="$OssAddress+'A.png'" style="margin-right: 20rem;width: 20rem;height: 20rem" alt="">
              <p style="color: #666">{{ item.answer }}</p>
            </div>
          </div>

          <div v-if="item.type === 1">
            <p>{{index + 1}}. {{ item.name }} <span style="color: #666">「 单选题 」</span></p>
            <div class="flex" style="margin-top: 10rem">
              <img :src="$OssAddress+'A.png'" style="margin-right: 20rem" alt="">
<!--              <p>A、{{ item.answer[0].name }}</p>-->
              <p style="color: #666">{{ item.answer[0].name }}</p>
            </div>
          </div>

          <div v-if="item.type === 2">
            <p>{{index + 1}}. {{ item.name }} <span style="color: #666">「 多选题 」</span></p>
            <div class="flex" style="align-items: flex-start">
              <img :src="$OssAddress+'A.png'" style="margin-right: 20rem;margin-top: 10rem" alt="">
              <div>
                <p style="margin-top: 10rem;color: #666" v-for="(c_item, c_index) in item.answer">
<!--                  {{c_index === 0 ? 'A、' :(c_index === 1 ? 'B、' : (c_index === 2 ? 'C、': (c_index === 3 ? 'D、' : 'E、')))}}{{ c_item.name }}-->
                  {{ c_item.name }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="item.type === 4">
            <p style="font-weight: bold;">{{index + 1}}. {{ item.name }} <span style="color: #666;font-weight: normal">「 矩阵题 」</span></p>

            <div v-for="(c_item, c_index) in item.child" style="margin-left: 20rem;margin-top: 30rem">
              <p>{{index + 1}}.{{c_index + 1}} {{ c_item.name }}
                <span v-if="c_item.type === 1" style="color: #666">「 单选题 」</span>
                <span v-if="c_item.type === 2" style="color: #666">「 多选题 」</span>
                <span v-if="c_item.type === 3" style="color: #666">「 填空题 」</span>
                <span v-if="c_item.type === 4" style="color: #666">「 矩阵题 」</span>
              </p>
              <div class="flex" style="align-items: flex-start">
                <img :src="$OssAddress+'A.png'" style="margin-right: 20rem;margin-top: 10rem" alt="">
                <div>
                  <p style="margin-top: 10rem;color: #666" v-for="(cc_item, cc_index) in c_item.answer">
<!--                    {{cc_index === 0 ? 'A、' :(cc_index === 1 ? 'B、' : (cc_index === 2 ? 'C、': (cc_index === 3 ? 'D、' : 'E、')))}}{{ cc_item.name }}-->
                    {{ cc_item.name }}
                  </p>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';
import infoItem from './components/infoItem'

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  // import 引入的组件需要注入到对象中才能使用
  components: {
    infoItem
  },
  props: {},
  data() {
    // 这里存放数据
    return {
      dataCont: ""
    }
  },
  // 监控 data 中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getData(){
      this.$_axios2('api/evaluation/psych/get-detail?id=' + this.$route.query.id).then(res => {
        // console.log(res)
        this.dataCont = res.data.data
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.getData()
  },
}
</script>
<style lang="scss" scoped>
::v-deep .image-slot {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header {
  display: flex;
  align-items: center;
  width: calc(100% - 85rem);
  height: 246rem;
  padding-left: 85rem;
  background: #FFF;

  .header-main {
    display: flex;
    width: 100%;
    height: 115rem;
    padding: 0;
  }
}

.header-img {
  position: relative;
  width: 115rem;
  height: 115rem;
}

.header-img-head-picture {
  width: 100%;
  height: 100%;
}

.header-img-gender {
  position: absolute;
  right: 5rem;
  bottom: 2rem;
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
}

.header-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 60rem;
}

.main {
  width: 100%;
  padding: 30rem;

  .main-title {
    font-size: 18rem;
    font-weight: bold;
  }

  .main-body {
    padding: 30rem 20rem;
  }
}
</style>
