<template>
  <div :style="{fontSize:size+'rem',width: width+'rem'}" style="display: flex">
    <div :style="{width:labelWidth+'rem'}" class="label">{{ label }}</div>
    <div class="value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: "infoItem",
  data() {
    return {}
  },
  props: {
    label: {
      type: String,
      default: '标题'
    },
    value: {
      type: String | Number,
    },
    labelWidth: {
      type: String,
      default: '120'
    },
    size: {
      type: String,
      default: '16'
    },
    width: {
      type: String,
      default: '200'
    },
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #666;
}

.value {
  font-weight: 400;
  color: #333;
}
</style>
